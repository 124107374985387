exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-all-services-js": () => import("./../../../src/pages/all-services.js" /* webpackChunkName: "component---src-pages-all-services-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-landing-page-home-alt-js": () => import("./../../../src/templates/landingPageHomeAlt.js" /* webpackChunkName: "component---src-templates-landing-page-home-alt-js" */),
  "component---src-templates-landing-page-home-js": () => import("./../../../src/templates/landingPageHome.js" /* webpackChunkName: "component---src-templates-landing-page-home-js" */),
  "component---src-templates-landing-page-iv-js": () => import("./../../../src/templates/landingPageIV.js" /* webpackChunkName: "component---src-templates-landing-page-iv-js" */),
  "component---src-templates-landing-page-nad-js": () => import("./../../../src/templates/landingPageNAD.js" /* webpackChunkName: "component---src-templates-landing-page-nad-js" */),
  "component---src-templates-landing-page-specific-iv-js": () => import("./../../../src/templates/landingPageSpecificIV.js" /* webpackChunkName: "component---src-templates-landing-page-specific-iv-js" */),
  "component---src-templates-landing-page-weightloss-js": () => import("./../../../src/templates/landingPageWeightloss.js" /* webpackChunkName: "component---src-templates-landing-page-weightloss-js" */),
  "component---src-templates-treatments-page-js": () => import("./../../../src/templates/treatmentsPage.js" /* webpackChunkName: "component---src-templates-treatments-page-js" */)
}

